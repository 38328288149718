.container {
  padding-top: 3rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  align-items: center;
  text-align: center;
}

.instructions p {
  padding: 0.5rem 0rem;
}

.instructions p span {
  font-weight: 450;
}

.startButton {
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.time {
  display: flex;
  margin-top: -1rem;
}

@media(max-width:1100px){ 
  .container { 
    padding-right: 1rem;
  }
  .time { 
    display: flex;
    flex-wrap: wrap;
  }  
  .time button {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
