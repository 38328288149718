.card {
  cursor: default;
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 32rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.card input {
  margin-left: auto;
  margin-right: auto;
}

.card h4 {
  padding-bottom: 0.5rem;
  padding-top: 0;
}

.card button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.cardStart {
  cursor: default;
  /* border-style: none; */
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 32rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.cardStart h4 {
  margin-top: 2rem;
}

.cardStartActive {
  cursor: pointer;
  border-width: 0.3rem;
  border-style: dashed;
  border-color: #ffd191ff;
}

.cardStartActive:hover {
  background-color: #ffd191ff;
}

.cardDiary {
  cursor: default;
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 32rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.cardDiary input {
  margin-left: auto;
  margin-right: auto;
}

.cardDiaryBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.cardDiaryBody input {
  font-size: 0.9rem;
  width: 100%;
}

.cardHead {
  display: flex;
  justify-content: space-between;
  /* padding: 0.5rem;  */
}

.cardMood {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.cardMood i {
  cursor: pointer;
}

.cardMoodActive {
  color: #ffb463ff;
}

.cardMoodActive {
  font-weight: bold;
}

.cardDiary hr {
  border-top: #9ca3af;
}

.cardHead i {
  padding-right: 0.5rem;
}

.cardHead div {
  display: flex;
  align-items: center;
}

.cardBody {
  padding: 1rem;
  /* background-color: #eee; */
  border: #eee;
  border-style: solid;
}

.cardBody p {
  padding-bottom: 0.3rem;
}

.cardMind {
  color: #00bcd4ff;
}

.cardBody span {
  display: flex;
  /* padding: 0.5rem; */
}

.cardBody span i {
  padding-right: 0.5rem;
  font-size: 1.2rem;
}

.cardMove span {
  color: #ff5252ff;
}

.cardNutri span {
  color: #009688ff;
}

.cardTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: blank;
  margin-top: 1rem;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  /* padding-right: 20rem; */
  text-align: center;
}

.container section {
  padding-bottom: 10rem;
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.actTech {
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 44rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.actTech h4 {
  padding: 0;
}

.actTechHead {
  display: flex;
  border-style: none;
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.actTechHead i {
  margin-left: auto;
}

.actTechHead h4 {
  padding-top: 0.5rem;
}

.error {
  margin-top: 1rem;
  text-align: center;
  color: red;
}

.mindGrid {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 0px 0px 12px 12px;
    padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 12rem;
  /* background-color: red; */
}

.myValues {
  display: flex;
}

.myValues label {
  margin-left: auto;
  margin-right: auto;
}

.name {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-22%,-50%);
  text-align: center;
}

.name h1 {
  font-family:'Montserrat';
  font-size: 2rem;
  letter-spacing: 2px;
  color:#ffb463ff;
  padding-bottom: 2rem;
}

.name h2 {
  font-size: 2rem;
  color:#ffb463ff;
  font-weight: 500;
  padding-top: 2rem;
}

.name p {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.name button {
  margin-left: auto;
  margin-right: auto;
}

.name img {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.cvBtn {
  width: 20rem;
  height: 3rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb463ff;
  box-shadow: 0px 5px 1px #ccc;
  border-radius: 10px;
  color:#ffffff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtn:hover {
  background-color: #ffd191ff;
  transition: all ease 0.5s;
  color: #ffffff;
}

.actTech:hover {
  background-color: #ccc;
}

.scheduleGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.scheduleGrid p {
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.scheduleGrid p:hover {
  font-weight: bold;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.materialIcon {
  color: #9ca3af;
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: block;
  position: fixed;
  left: 30%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* ACERTAR REZISE */
  min-width: 38rem;
  height: auto;
  /* max-width: 32rem; */
  /* ACERTAR REZISE */
  /* min-height: 15rem; */
  /* max-height: 15rem; */
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalFooter {
  display: flex;
  /* flex-direction: column; */
  /* border-top-width: 1px; */
  justify-content: center;
  padding: 0.75rem;
  width: 100%;
}

.modalFooter button {
  margin-top: 1rem;
}

.modalHeader {
  display: flex;
  background-color: #fff;
  justify-content: right;
  align-items: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.modalBody {
  padding: 2rem;
  text-align: justify;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-height: 23rem;
  max-height: auto;
  overflow-y: scroll;
}

.modalBody p {
  padding-bottom: 0.5rem;
}

/* .modalBody p:hover {
  font-weight: bold;
  cursor: pointer;
} */

.modalBody button {
  margin-bottom: 1rem;
}

.modalBody h3 {
  padding-bottom: 1rem;
  text-align: center;
}

.topSection {
  display: flex;
}

/* Button */

.confirmation {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 14px;
  color: black;
  /* color: #888; */
  /* text-transform: capitalize; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.confirmation input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

.confirmation img {
width: 100%;
height: auto;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
left: 6px;
top: 2px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
background-color: #009688ff;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.wordCheck input:checked ~ .checkmark:after {
  display: block;
  }
  
  /* Style the checkmark/indicator */
  .wordCheck .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  }
  
  /* On mouse-over, add a grey background color */
  .wordCheck:hover input ~ .checkmark {
  background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .wordCheck input:checked ~ .checkmark {
  background-color: #009688ff;
  }

  @media(max-width:1100px){
    .modal {
      left: 23%;
      top: 3%;
    }
  }
  @media(max-width:800px){
    .modal {
      min-width: 30rem;
      left: 4%;
      top: 3%;
    }
  }
  