body{
  margin:0px;
  padding: 0px;
  background-color: #ffffff;
}

*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}

.logo img {
  padding-top: 0.5rem;
  width: 120px;
}

.menu div {
  display: flex;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 0.5rem;
  width:100%;
  z-index: 1;
  /* background-color: transparent; */
  transition: 0.6s ease-in-out;
}

.nav.active{
  box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
  background-color: #ffffff;
}

.nav ul {
  display: flex;
}

.nav ul li a {
  height:40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color:#000000; 
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

.nav .menuBtn,
.menuIcon {
  display:none;
}

.scroll{
  left: 42.5%;
}

.topbarIcon {
  font-size: 0.9rem;
  padding-right: 12px;
  color: #4b5563;
}

.topbarIcon.active {
  font-size: 0.9rem;
  padding-right: 16px;
  color: #FF9F38;
}

.topbarIcon.disabled {
  font-size: 0.9rem;
  padding-right: 16px;
  color: #bbb;
}

.topbarListItem {
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.topbarListItem:hover {
  background-color: #cccccc44;
}

.topbarListItem.active {
  font-weight: 550;
  color: #FF9F38;
  background-color:#FFF0DB;
}

@media(max-width:1100px){
  .menuIcon {
      display:block;
      }
  .nav {
      justify-content: space-between;
      height: 65px;
      padding: 0px 30px;
      }
    .logo img{
        width:120px;
    }
    .header-btns{
        margin: 0;
    }
    .menu{
        display:none;
        position:absolute;
        top:65px;
        left:0px;
        background-color:#FFFFFF;
        border-bottom:4px solid #FF9F38;
        width:100%;
        padding:0px;
        margin:0px;
    }
    .menu div {
      display: block;
    }
    .menu li{
        width:100%;
    }
    .nav .menu li a{
        width:100%;
        height:40px;
        justify-content: center;
        align-items: center;
        margin:0px;
        padding: 25px;
        border:1px solid rgba(38,38,38,0.03);
    }
    .nav .menuIcon{
        cursor:pointer;
        float:right;
        padding:28px 20px;
        position:relative;
        user-select: none;
    }
    .nav .menuIcon .navIcon{
        background-color:#333333;
        display:block;
        height:2px;
        position:relative;
        transition: background 0.2s ease-out;
        width:18px;
    }
    .nav .menuIcon .navIcon:before,
    .nav .menuIcon .navIcon:after{
        background:#333;
        content:'';
        display:block;
        height:100%;
        position:absolute;
        transition:all ease-out 0.2s;
        width:100%;
    }
    .nav .menuIcon .navIcon:before{
        top:5px;
    }
    .nav .menuIcon .navIcon:after{
        top:-5px;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon{
        background:transparent;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:before{
        transform: rotate(-45deg);
        top:0;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:after{
        transform: rotate(45deg);
        top:0;
    }
    .nav .menu {
        display:none;
    }
    .nav .menuBtn:checked ~ .menu{
        display:block;
    }
  }

