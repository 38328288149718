.container {
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.3fr;
  top: 1.8rem;
  margin-top: 3.5rem;
  /* bottom: 6px; */
  /* right: 0rem; */
  border-radius: 3px;
  /* padding-left: 10px; */
  box-sizing: border-box;
  overflow-y: scroll;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.noSidebar {
  position: absolute;
  left: 6rem;
  top: 1.8rem;
  /* bottom: 6px; */
  right: 6px;
  border-radius: 3px;
  padding-left: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

@media(max-width:1100px){
  .container {
    grid-template-columns: 0.1fr 1fr 0.1fr;
  }
}