h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

h2 {
  color: #6b7280;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

h4 {
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

input {
  display: block;
  font-family: var(--type-first);
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.75rem;
  width: 100%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  background-color: #f3f4f6;
}

input:focus {
  border-color: #3b82f6;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

a {
  text-decoration: none;
  color: #333;
}

.containerContent {
  padding-top: 3rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  /* align-items: center;
  text-align: center; */
}

/* BUTTONS */

.defaultButton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e1e1;
  background: white;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.defaultButton p {
  padding-right: 0.3rem;
}

.defaultButton:hover {
  outline: none;
  background: #eee;
}

.defaultButton:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.defaultButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.defaultActiveButton {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e1e1;
  background: #6b7280;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: white;
}

.defaultActiveButton p {
  padding-right: 0.3rem;
}

.defaultActiveButton:hover {
  outline: none;
  background: #eee;
  color: #6b7280;
}

.defaultActiveButton:after {
  content: "";
  background: #6b7280;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.defaultActiveButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.saveButton {
  background-color: #3b82f6;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #ffffff;
  border-radius: 0.25rem;
  border: none;
}

.saveButton:hover {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #2563eb;
  color: #ffffff;
  border-radius: 0.25rem;
}

.deleteButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #d32f2fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #d32f2fff;
  border-radius: 0.25rem;
}

.deleteButton:hover {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #d32f2fff;
  color: white;
  border-radius: 0.25rem;
}

.createNewButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #00796b;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #00796b;
  border-radius: 0.25rem;
}

.createNewButton:hover {
  background-color: #00796b;
  color: white;
}

.redButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #d32f2fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #d32f2fff;
  border-radius: 0.25rem;
}

.redButton:hover {
  background-color: #d32f2fff;
  color: white;
}

.orangeButton {
  cursor: pointer;
  background-color: #ffb463ff;
  border: 1px solid #ffb463ff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
  border-radius: 0.25rem;
  font-weight: 600;
}

.orangeButton:hover {
  filter: brightness(110%);
}

.orangeOutButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #ffb463ff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #ffb463ff;
  border-radius: 0.25rem;
}

.orangeOutButton:hover {
  background-color: #ffb463ff;
  color: white;
}

.blueButton {
  cursor: pointer;
  background-color: #0097a7ff;
  border: 1px solid #0097a7ff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
  border-radius: 0.25rem;
}

.blueButton:hover {
  filter: brightness(110%);
}

.blueOutButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #0097a7ff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #0097a7ff;
  border-radius: 0.25rem;
}

.blueOutButton:hover {
  background-color: #0097a7ff;
  color: white;
}

.greenButton {
  cursor: pointer;
  background-color: white;
  border: 1px solid #009688ff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #009688ff;
  border-radius: 0.25rem;
}

.greenButton:hover {
  background-color: #009688ff;
  color: white;
}

/* COLORS */

.color0 {
  background-color: #8fcaca;
}

.color1 {
  background-color: #eceae4;
}

.color2 {
  background-color: #ffbf91;
}

.color3 {
  background-color: #d4d479;
}

.color4 {
  background-color: #ecd5e3;
}

.color5 {
  background-color: #cce2cb;
}

.color6 {
  background-color: #ffffb5;
}

.color7 {
  background-color: #97c1a9;
}

.color8 {
  background-color: #d7efef;
}

.color9 {
  background-color: #dba194;
}

.color10 {
  background-color: #c6dbda;
}

.color11 {
  background-color: #ffe3d4;
}

.color12 {
  background-color: #f6eac2;
}

.color13 {
  background-color: #e67c73;
}

.color14 {
  background-color: #c8c2ff;
}

.color15 {
  background-color: #7CB9E8;
}

.color16 {
  background-color: #72A0C1;
}

.color17 {
  background-color: #e6b2a8;
}

.color18 {
  background-color: #c9c09f;
}

/* CHECKMARKS */

.body {
  padding-top: 1rem;
}

/* The container */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 14px;
  color: #4b5563;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkbox input:checked ~ .color0 {
  background-color: #8fcaca;
}

.checkbox input:checked ~ .color1 {
  background-color: #eceae4;
}

.checkbox input:checked ~ .color2 {
  background-color: #ffbf91;
}

.checkbox input:checked ~ .color3 {
  background-color: #d4d479;
}

.checkbox input:checked ~ .color4 {
  background-color: #ecd5e3;
}

.checkbox input:checked ~ .color5 {
  background-color: #cce2cb;
}

.checkbox input:checked ~ .color6 {
  background-color: #ffffb5;
}

.checkbox input:checked ~ .color7 {
  background-color: #97c1a9;
}

.checkbox input:checked ~ .color8 {
  background-color: #d7efef;
}

.checkbox input:checked ~ .color9 {
  background-color: #dba194;
}

.checkbox input:checked ~ .color10 {
  background-color: #c6dbda;
}

.checkbox input:checked ~ .color11 {
  background-color: #ffe3d4;
}

.checkbox input:checked ~ .color12 {
  background-color: #f6eac2;
}

.checkbox input:checked ~ .color13 {
  background-color: #e67c73;
}

.checkbox input:checked ~ .color14 {
  background-color: #c8c2ff;
}

.checkbox input:checked ~ .color15 {
  background-color: #7CB9E8;
}

.checkbox input:checked ~ .color16 {
  background-color: #72A0C1;
}

.checkbox input:checked ~ .color17 {
  background-color: #e6b2a8;
}

.checkbox input:checked ~ .color18 {
  background-color: #c9c09f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* COSMETIC STYLING */

:root {
  --red: #ed5454;
}

body {
  font-family: Montserrat;
  font-weight: 400;
  color: #6b7280;
  margin: 0;
  padding: 0;
}

html {
  margin: 0;
  padding: 0;
}

.menu__item {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 10px 12px 10px 14px;
  border-left: 4px solid transparent;
  color: inherit;
  text-decoration: none;
  transition: color ease 0.3s;
}

.menu__item--active .menu__icon {
  color: var(--red);
}
.menu__item--active .menu__text {
  color: black;
}

.menu__item:hover {
  color: black;
}

.copyright {
  font-size: 0.7rem;
  font-weight: 400;
}

.materialButton {
  background: transparent;
  border: none;
}

.materialIcon {
  color: #4b5563;
  cursor: pointer;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.materialIcon:hover {
  outline: none;
  background: #eee;
  border-radius: 50%;
}

.materialButton {
  background: transparent;
  border: none;
}

.toggle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  font-size: 0.9em;
}
.toggle__option {
  border: 1px solid #e1e1e1;
  text-transform: capitalize;
  cursor: pointer;
}
.toggle__option:first-child {
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.toggle__option:last-child {
  border-radius: 8px;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.toggle__option--selected {
  border-color: white;
  background-color: white;
  color: var(--red);
  font-weight: 500;
  box-shadow: 1px 2px 30px -5px var(--red);
}
