/* General Styles */
body {
  font-family:'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  /* background-color: #EBF4F6; */
  /* color: #071952; */
}

.about {
  display: flex;
  padding: 4rem 1rem;
  padding-left: 15rem;
  padding-right: 15rem;
  text-align: justify;
  background-color: #EBF4F6; /* Background color for hero section */
  color: #071952; /* Dark navy text for hero section */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.about img {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.aboutContainer {
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.aboutContainer p {
  font-family:'Montserrat';
  padding-right: 4rem;
  line-height: 1.5rem;
}

.aboutContainer h1 {
  font-family:'Montserrat';
  font-size: 2rem;
  letter-spacing: 2px;
  color:#ffb463ff;
  padding-bottom: 2rem;
}

.container {
  cursor: default;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: #071952;
}

header {
  background: transparent; /* Initial transparent background */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000; /* Ensure it stays on top of other content */
  width: 100%;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for background color and shadow */
}

header.scrolled {
  background-color: #EBF4F6; /* Background color after scrolling past hero section */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

header .logo ion-icon {
  font-size: 50px; /* Adjust size to match the original logo height */
}

header nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

header nav ul li {
  margin: 0 15px;
}

header nav ul li:last-child {
  margin-right: 30px; /* Add extra space after the last item (Contact) */
}

header nav ul li a {
  color: #071952; /* Dark navy text for links */
  font-weight: bold;
  transition: color 0.3s;
}

header nav ul li a:hover {
  color: #088395; /* Darker teal on hover */
}

.cvBtn {
  width: 20rem;
  height: 3rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb463ff;
  box-shadow: 0px 5px 1px #ccc;
  border-radius: 10px;
  color:#ffffff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtn:hover {
  background-color: #ffd191ff;
  transition: all ease 0.5s;
  color: #ffffff;
}

.cvBtnIn {
  width: 20rem;
  height: 3rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffff;
  /* box-shadow: 5px 10px 30px #ccc; */
  box-shadow: 0px 5px 1px #ddd;
  border-style: solid;
  border-radius: 10px;
  border-color: #ddd;
  border-width: 0.2rem;
  color:#00bcd4ff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtnIn:hover {
  background-color: #eee;
  transition: all ease 0.5s;
  color:#00bcd4ff;
}

#hero {
  background-color: #EBF4F6; /* Background color for hero section */
  color: #071952; /* Dark navy text for hero section */
  text-align: center;
  padding: 4rem 1rem;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

#hero .ctaBtn {
  background-color: #37B7C3; /* Primary color for CTA button */
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

#hero .ctaBtn:hover {
  background-color: #088395; /* Darker teal on hover */
}

#hero img {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#hero h1 {
  font-family:'Montserrat';
  font-size: 2rem;
  letter-spacing: 2px;
  color:#ffb463ff;
  padding-bottom: 1rem;
}

#hero p {
  font-family:'Montserrat';
  font-size: 0.9rem;
  letter-spacing: 2px;
  color:#444;
  padding-bottom: 2rem;
}

.features {
  background-color: #37B7C3; /* Primary color for features section */
  padding: 2rem 1rem;
  text-align: center;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.features h2 {
  color: white; /* White text for contrast */
  padding-bottom: 1rem;
}


.featuresContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.featureCard {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 250px; /* Use max-width instead of width to be more flexible */
  min-width: 250px; /* Use max-width instead of width to be more flexible */
  text-align: center;
  color: #071952; /* Dark navy text */
}

.featureCard i {
  font-size: 30px; /* Adjust icon size as needed */
  color: #071952; /* Dark navy color */
  margin-bottom: 1rem; /* Space between icon and text */
}

.howItWorks {
  background-color: #37B7C3; /* Primary color for how it works section */
  padding: 2rem 1rem;
  
  text-align: center;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.howItWorks h2 {
  color: white; /* White text for contrast */
  padding-bottom: 1rem;
}

.stepsContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.step {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 250px; /* Use max-width instead of width to be more flexible */
  text-align: center;
  color: #071952; /* Dark navy text */
}

.step ion-icon {
  font-size: 50px; /* Adjust icon size as needed */
  color: #071952; /* Dark navy color */
  margin-bottom: 1rem; /* Space between icon and text */
}

.step p {
  padding-top: 1rem;
}

.resources {
  background-color: #37B7C3; /* Primary color for resources section */
  padding: 2rem 1rem;
  text-align: center;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.resources h2 {
  color: #EBF4F6; /* Dark navy text for contrast */
  padding-bottom: 1rem;
}

.resourcesContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.resourceCard {
  background-color: white; /* Background color for card */
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 250px; /* Use max-width instead of width to be more flexible */
  text-align: center;
  color: #071952; /* Dark navy text */
}

.resourceCard h3 {
  margin-top: 0;
}

.resourceCard p {
  margin-top: 1rem;
}

.resourceLink {
  color: #37B7C3; /* Primary color for link text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make text clickable */
  cursor: pointer; /* Show pointer cursor on hover */
  margin-top: 1rem; /* Add space between title and text */
  font-size: 0.9rem; /* Adjust font size as needed */
}

.resourceLink:hover {
  color: #088395; /* Darker teal on hover */
  text-decoration: underline; /* Optional: underline text on hover */
}

#faqList.hidden {
  display: none;
}

#faqToggle {
  background-color: #37B7C3; /* Primary color for the FAQ toggle button */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

#faqToggle:hover {
  background-color: #088395; /* Darker teal on hover */
}

.footer {
  background-color: #EBF4F6; /* Dark navy for footer */
  color: black;
  padding: 2rem 1rem;
  padding-top: 6rem;
  text-align: center;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

.footerLinks, .footerSocial, .footerContact, .newsletterSignup {
  margin: 1rem 0;
}

.footerLinks a {
  color: white;
  margin: 0 10px;
  font-weight: bold;
}

.footerLinks a:hover {
  color: #37B7C3; /* Primary color on hover */
}

.footerSocial a {
  margin: 0 10px;
}

.footerSocial ion-icon {
  font-size: 30px; /* Adjust size as needed */
  color: white; /* Color of the social media icons */
}

.newsletterSignup input {
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
}

.newsletterSignup .ctaBtn {
  background-color: #37B7C3; /* Primary color for newsletter CTA */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.newsletterSignup .ctaBtn:hover {
  background-color: #088395; /* Darker teal on hover */
}

.icon {
  font-size: 2rem; /* Adjust the size as needed */
  color: #071952; /* Dark navy color */
  margin-bottom: 1rem; /* Space between icon and text */
}

@media(max-width:1200px) {
  .about {
    display: block;
  }
}

.aboutContainer p {
  font-family:'Montserrat';
  padding-right: 4rem;
  line-height: 1.5rem;
}

  @media(max-width:1000px) {
  .about {
    padding-left: 5rem;
    padding-right: 1rem;
  }
}

/* NAV */

.logo img {
  padding-top: 0.5rem;
  width: 200px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}

.nav.active{
  box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
  background-color: #ffffff;
}

.nav ul {
  display: flex;
}

.active{
  background-color: #ffb463ff;
  color: #ffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  transition: all ease 0.2s;
  font-weight: bold;
}

.nav .menuBtn,
.menuIcon {
  display:none;
}

.scroll{
  left: 42.5%;
}

@media(max-width:1100px){
  .menuIcon {
      display:block;
      }
  .nav {
      justify-content: space-between;
      height: 65px;
      padding: 0px 30px;
      }
    .logo img{
        width:120px;
    }
    .header-btns{
        margin: 0;
    }
    .menu{
        display:none;
        position:absolute;
        top:65px;
        left:0px;
        background-color:#FFFFFF;
        border-bottom:4px solid #1db096;
        width:100%;
        padding:0px;
        margin:0px;
    }
    .menu li{
        width:100%;
    }
    .nav .menu li a{
        width:100%;
        height:40px;
        justify-content: center;
        align-items: center;
        margin:0px;
        padding: 25px;
        border:1px solid rgba(38,38,38,0.03);
    }
    .nav .menuIcon{
        cursor:pointer;
        float:right;
        padding:28px 20px;
        position:relative;
        user-select: none;
    }
    .nav .menuIcon .navIcon{
        background-color:#333333;
        display:block;
        height:2px;
        position:relative;
        transition: background 0.2s ease-out;
        width:18px;
    }
    .nav .menuIcon .navIcon:before,
    .nav .menuIcon .navIcon:after{
        background:#333;
        content:'';
        display:block;
        height:100%;
        position:absolute;
        transition:all ease-out 0.2s;
        width:100%;
    }
    .nav .menuIcon .navIcon:before{
        top:5px;
    }
    .nav .menuIcon .navIcon:after{
        top:-5px;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon{
        background:transparent;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:before{
        transform: rotate(-45deg);
        top:0;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:after{
        transform: rotate(45deg);
        top:0;
    }
    .nav .menu {
        display:none;
    }
    .nav .menuBtn:checked ~ .menu{
        display:block;
    }
  }