.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: white;
  border-style: solid;
  border-color: #eee;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 50rem;
  height: 16rem;
}

.container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.id {
  display: flex;
}

.user p {
  font-weight: 500;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.user span {
  font-weight: 300;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.userName {
  color: #6b7280;
}

.userName:hover {
  color: black;
  text-decoration: underline;
}

.cardIcon {
  font-size: 3rem;
}

.place p {
  cursor: pointer;
}

.place p:hover {
  color: black;
}

.place button {
  margin-top: 2rem;
}

.timeSec h5 {
  margin: 0;
  margin-bottom: 1rem;
}

.timeDiv {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.time {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.time:hover {
  color: black;
}

.activeTime {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-weight: bolder;
}

.disabledTime {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-decoration: line-through;
}