body{
  margin:0px;
  padding: 0px;
  background-color: #ffffff;
}

*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}

.btnStart {
  width: 10rem;
  height: 2.5rem;
  margin-top: 3rem;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb463ff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffffffff;
  cursor: pointer;
  font-weight: bold;
}

.btnStart:hover {
  background-color: #ffd191ff;
  transition: all ease 0.5s;
  color: #ffffff;
}

.btnIn {
  width: 10rem;
  height: 2.5rem;
  margin-top: 3rem;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffb463ff;
  cursor: pointer;
  font-weight: bold;
}

.btnIn:hover {
  background-color: #eee;
  transition: all ease 0.5s;
  color:#ffb463ff;
}

.logo img {
  padding-top: 0.5rem;
  width: 200px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}

.nav.active{
  box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
  background-color: #ffffff;
}

.nav ul {
  display: flex;
}

.active{
  background-color: #ffb463ff;
  color: #ffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  transition: all ease 0.2s;
  font-weight: bold;
}

/* .nav ul li a {
  height:40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color:#000000; 
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
} */

/* 
.nav ul li a:hover{
  background-color: #ffb463ff;
  color:#ffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  transition: all ease 0.2s;
  font-weight: bold;
} */

.nav .menuBtn,
.menuIcon {
  display:none;
}

.scroll{
  left: 42.5%;
}

@media(max-width:1100px){
  .menuIcon {
      display:block;
      }
  .nav {
      justify-content: space-between;
      height: 65px;
      padding: 0px 30px;
      }
    .logo img{
        width:120px;
    }
    .header-btns{
        margin: 0;
    }
    .menu{
        display:none;
        position:absolute;
        top:65px;
        left:0px;
        background-color:#FFFFFF;
        border-bottom:4px solid #1db096;
        width:100%;
        padding:0px;
        margin:0px;
    }
    .menu li{
        width:100%;
    }
    .nav .menu li a{
        width:100%;
        height:40px;
        justify-content: center;
        align-items: center;
        margin:0px;
        padding: 25px;
        border:1px solid rgba(38,38,38,0.03);
    }
    .nav .menuIcon{
        cursor:pointer;
        float:right;
        padding:28px 20px;
        position:relative;
        user-select: none;
    }
    .nav .menuIcon .navIcon{
        background-color:#333333;
        display:block;
        height:2px;
        position:relative;
        transition: background 0.2s ease-out;
        width:18px;
    }
    .nav .menuIcon .navIcon:before,
    .nav .menuIcon .navIcon:after{
        background:#333;
        content:'';
        display:block;
        height:100%;
        position:absolute;
        transition:all ease-out 0.2s;
        width:100%;
    }
    .nav .menuIcon .navIcon:before{
        top:5px;
    }
    .nav .menuIcon .navIcon:after{
        top:-5px;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon{
        background:transparent;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:before{
        transform: rotate(-45deg);
        top:0;
    }
    .nav .menuBtn:checked ~ .menuIcon .navIcon:after{
        transform: rotate(45deg);
        top:0;
    }
    .nav .menu {
        display:none;
    }
    .nav .menuBtn:checked ~ .menu{
        display:block;
    }
  }

/* #########  EXCLUIR ######## */

/* .header-btns{
  display: flex;
  margin-top: 40px;
}

.f-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.f-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -80px;
}

.a-box{
  background-color: #ffffff;
  width:300px;
  height: 450px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 12px rgba(0,0,0,0.1);
  transition: 0.3s ease-in-out;
  position: relative;
}

.a-box:hover{
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
}

#services{
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
}

.s-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.s-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.s-heading{
  text-align: center;
  margin: 20px 0px;
}
.s-box{
  background-color: #ffffff;
  width:350px;
  height: 470px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
  position: relative;
}
.b-container{
  display: flex;
  justify-content: center;
  align-content: center;
}
.s-b-img{
  width: 100%;
  height: 50%;
}
.s-b-img img{
  width:100%;
  height: 100%;
}

.s-type{
  font-family: calibri;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}

@media(max-width:1190px){

  #services{
  height:auto;
  }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
  }
} 

@media(max-width:1190px){

  #services{
  height:auto;
  }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
  }
}

.header-btns{
  display: flex;
  margin-top: 40px;
}

.f-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.f-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -80px;
}

.a-box{
  background-color: #ffffff;
  width:300px;
  height: 450px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 12px rgba(0,0,0,0.1);
  transition: 0.3s ease-in-out;
  position: relative;
}

.a-box:hover{
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
}

#services{
  width:100%;
  height:100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
}

.s-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.s-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.s-heading{
  text-align: center;
  margin: 20px 0px;
}
.s-box{
  background-color: #ffffff;
  width:350px;
  height: 470px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
  position: relative;
}
.b-container{
  display: flex;
  justify-content: center;
  align-content: center;
}
.s-b-img{
  width: 100%;
  height: 50%;
}
.s-b-img img{
  width:100%;
  height: 100%;
}

.s-type{
  font-family: calibri;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}

  @media(max-width:400px){
      .s-box{
          width: 100%;
          height:400px;
      }
  }

*/