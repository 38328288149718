.container {
  padding-top: 3rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  /* align-items: center;
  text-align: center; */
}

.content {
  text-align: justify;
}

.content p {
  margin-right: 7rem;
}

.title {
  display: flex;
  align-items: center;
}

.title h4 {
  padding-left: 0.5rem;
}

.title p {
  cursor: pointer;
  padding-right: 1rem;
  font-weight: 600;
}

.activeMind {
  color: #00bcd4ff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.activeMind h4 {
  padding-left: 0.5rem;
}

.activeMove {
  color: #ff5252ff;
  font-weight: 600;
}

.activeNutri {
  color: #009688ff;
  font-weight: 600;
}

.myActivities {
  display: flex;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid #eee;
  width: 47rem;
}

.featureCard {
  display: flex;
  cursor: pointer;
}

.featureImg {
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}

.modal {
  display: block;
  position: fixed;
  left: 30%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 45rem;
  max-width: 45rem;
  min-height: 30rem;
  max-height: 30rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  padding: 2rem;
  text-align: justify;
  overflow-y: scroll;
  min-height: 28rem;
  max-height: 28rem;
}

.modalBody h3 {
  padding-bottom: 1rem;
  text-align: center;
}

.modalImg {
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.back {
  cursor: pointer;
}