.container {
  padding-top: 3rem;
  padding-right: 20rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
}

.container h1 {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.container h2 {
  text-align: center;
  margin-bottom: 0.5rem;
}

/* .container h4 {
  margin-bottom: 0rem;
  margin-top: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
} */

/* .container h5 {
  margin-left: 15rem;
  margin-bottom: 0.5rem;
} */

.container p {
  text-align: justify;
  margin-top: 0.7rem;
}

.container strong {
  text-align: justify;
  margin-top: 0.7rem;
  font-size: 1rem;
}

.container hr {
  text-align: justify;
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 0.7rem;
}

.container span {
  font-weight: bold;
}

.container label {
  font-weight: bold;
}

.container form p {
  margin-left: 0rem;
}

button {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.questions {
  padding-bottom: 1rem;
}

.confirmation {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    padding-top: 0.2rem;
    cursor: pointer;
    font-size: 12px;
    color: #888;
    /* text-transform: capitalize; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: auto;
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.confirmation input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.confirmation img {
  width: 100%;
  height: auto;
}

/* Show the checkmark when checked */
.confirmation input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.confirmation .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.confirmation:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.confirmation input:checked ~ .checkmark {
  background-color: #009688ff;
}

.wordCheck {
    display: block;
    position: relative;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.2rem;
    cursor: pointer;
    font-size: 12px;
    color: #4b5563;
    text-transform: capitalize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* margin-right: auto; */
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.wordCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.wordCheck img {
  width: 100%;
  height: auto;
}

/* Show the checkmark when checked */
.wordCheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.wordCheck .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.wordCheck:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.wordCheck input:checked ~ .checkmark {
  background-color: #009688ff;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.iti {
  background-color: gray;
  width: 100%;
  height: 100%;
}

/* SAM */

.samScale {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

.samScale h1 {
  text-transform: capitalize;
}

.samScale input {
  display: none;
}

.samItens {
  display: flex;
  color: black
}

.samControl p {
  text-align: center;
}

.samScale h1 {
  /* text-align: center; */
  margin-top: 5rem;
  margin-bottom: 7rem;
  margin-left: 18rem;
  color: black;
}

.samScale form {
  margin-bottom: 7rem;
}

.error {
  color: red;
}

.instructions {
  padding-bottom: 2rem;
}