.activeMind {
  color: #00bcd4ff;
  font-weight: 600;
}

.activeMove {
  color: #ff5252ff;
  font-weight: 600;
}

.activeNutri {
  color: #009688ff;
  font-weight: 600;
}

.card {
  cursor: pointer;
  border-style: none;
  display: inline-block;
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 42rem;
  min-height: 12rem;
  overflow-y: scroll;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffb46333;
}

.card h4 {
  padding: 1rem;
}

.card table {
  border-collapse: collapse;
  width: 100%;
}

.card th, td {
  text-align: left;
  padding: 8px;
}

.card th {
  padding: 1rem;
}

.card tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cardContent {
  width: 100%;
  min-height: 12rem;
}

.cardDisabled {
  cursor: pointer;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  margin-bottom: 3rem;
  width: 42rem;
  min-height: 12rem;
  overflow-y: scroll;
  background-color: #eee;
}

.cardIcon {
  cursor: pointer;
  float: right;
  padding: 0.5rem;
}

.cardIcon:hover {
  background-color: #eee;
  border-radius: 60%;
}

.cardItem {
  padding: 0.3rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

/* .cardItem:hover {
  background-color: #eee;
  border-radius: 10px;
} */

.cardItemActive {
  cursor: pointer;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #eee;
  border-radius: 10px;
  color: #00bcd4ff;
}

.container {
  padding-top: 3rem;
  padding-right: 20rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  /* align-items: center;
  text-align: center; */
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.content {
  text-align: justify;
}

.content p {
  margin-right: 7rem;
}

.cvBtnIn {
  width: 20rem;
  height: 2.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffb463ff;
  cursor: pointer;
  font-weight: bold;
}

.cvBtnIn:hover {
  background-color: #eee;
  transition: all ease 0.5s;
  color:#ffb463ff;
}

.title {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.title h4 {
  padding-left: 0.5rem;
}

.title p {
  cursor: pointer;
  padding-right: 1rem;
  font-weight: 600;
}

.myActivities {
  display: flex;
}

.feature {
  display: flex;
  cursor: pointer;
  border-style: double;
  /* Add shadows to create the "card" effect */
  /* box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.5); */
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  margin-right: 5rem;
  min-width: 10rem;
  min-height: 4rem;
  overflow-y: scroll;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.featureMind:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: #00bcd4ff;
}

.featureMove:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  /* background-color: #ffcdd2ff; */
  color: #ff5252ff;
}

.featureNutri:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: #009688ff;
}

.feature p {
  padding-left: 0.5rem;
  font-weight: 600;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid #eee;
  width: 47rem;
}

.featureCard {
  display: flex;
  cursor: pointer;
}

.featureImg {
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

.error {
  color: red;
  margin-top: 0.5rem;
}

.appointmentModal {
  display: block;
  position: fixed;
  left: 30%;
  top: 5%;
  max-width: 60%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.appointmentModal p {
  padding: 0.5rem;
}

.appointmentModal div {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.footer {
  padding-bottom: 5rem;
}

.image {
  width: 6rem;
  height: 6rem;
}

.modal {
  display: block;
  position: fixed;
  left: 30%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 45rem;
  max-width: 45rem;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  padding: 2rem;
  text-align: justify;
  overflow-y: scroll;
  min-height: 28rem;
  max-height: 28rem;
}

.modalBody h3 {
  padding-bottom: 1rem;
  text-align: center;
}

.modalImg {
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.back {
  cursor: pointer;
}

.profile {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 155px;
  grid-gap: 20px;
  padding: 1.5rem;
  /* background-color: #2196F3; */
}

.profile h4 {
  padding: 1rem;
}

.profile p {
  padding-left: 1rem;
}

.profile1 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.profile2 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 4;
}

.profile3 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.profile4 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
}

.profile5 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.profile6 {
  background-color:#FFF0DB;
  border-radius: 10px;
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}

.title {
  display: flex;
}

.title h4 {
  display: flex;
  cursor: default;
}

.title i {
  display: flex;
  cursor: pointer;
}

.userIcon {
  float: left;
  height: 30px;
  width: 30px;
  margin-top: 0.7rem;
  margin-left: 0.6rem;
}
