.smallcalendar {
  padding-bottom: 1rem;
  min-width: 10rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}

.smallcalendar h3 {
  color: #6b7280;
  font-size: 0.6rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.materialIcon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #4b5563;
  cursor: pointer;
}

.button {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  border-width: 0.1px;
  border-color: #eee;
  background: white;
  cursor: pointer;
}

.button:hover {
  outline: none;
  background: #eee;
}

.arrowButton {
  cursor: pointer;
  background: transparent;
  border: none;
  padding-inline: 0;
}

.arrowButtonDiv {
  display: flex;
}

.smallCalendarCells {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.cell {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 0.5rem;
  line-height: 1.25rem;
  text-align: center;
  color: #6b7280;
}

.cellText {
  font-size: 0.5rem;
  line-height: 1.25rem;
}

.cellButton {
  padding-top: 0rem;
  padding-bottom: 0rem;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #6b7280;
}

.cellButton:hover {
  background-color: #f3f4f6;
  color: #6b7280;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.currentDay {
  background-color: #2563eb;
  color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}

.selectedDay {
  background-color: #bfdbfe;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
}
