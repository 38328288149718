.container {
  padding-top: 3rem;
  padding-right: 20rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  align-items: center;
  text-align: center;
}

.typeBar {
  display: flex;
}

.typeBar button {
  margin-left: 4rem;
}


.footer {
  padding-bottom: 5rem;
}

.image {
  width: 6rem;
  height: 6rem;
}

/* .footer button {
  margin-left: auto;
  margin-right: auto;
} */