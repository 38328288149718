.appointmentModal {
  display: block;
  position: fixed;
  left: 40%;
  top: 10%;
  max-width: 40%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.appointmentModal p {
  padding: 0.5rem;
}

.appointmentModal div {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.card {
  display: block;
  background-color: white;
  border-style: solid;
  border-color: #eee;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 50rem;
  height: 15rem;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card p {
  padding: 0.5rem;
}

.card button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

.appointmentModal {
  display: block;
  position: fixed;
  left: 40%;
  top: 10%;
  max-width: 40%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.appointmentModal p {
  padding: 0.5rem;
}

.appointmentModal div {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}