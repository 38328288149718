.container {
  padding-top: 3rem;
  background-color: blank;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  text-align: justify;
  /* align-items: center;
  text-align: center; */
}

.container p {
  margin-bottom: 1rem;
}

.container h2 {
  cursor: pointer;
}

.container h2:hover {
  text-decoration: underline;
}

.month {
  display: grid;
  cursor: default;
  width: 100%;
  grid-template-columns: repeat(7, minmax(40px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: minmax(250px, auto);
  overflow: auto;
}

.moodIcon {
  font-size: 1.3rem;
}

.cell_title {
  font-size: 12px;
  text-transform: uppercase;
  color: #99a1a7;
  text-align: center;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
    /* Hide the text. */
    text-indent: 5%;
    white-space: nowrap;
    overflow: hidden;
}

.day_name {
  font-size: 12px;
  color: #99a1a7;
  /* color: white; */
  text-align: center;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
  text-overflow: ellipsis;
  padding: 0.2rem;
  /* Hide the text. */
  /* text-indent: 5%;
  white-space: nowrap;
  overflow: hidden; */
}

.currentDay {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  color: #6b7280;
  background-color: #2563eb;
  color: #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-left: auto;
  margin-right: auto;
}

.eventsModal {
  display: flex;
  position: absolute;
  left: 40%;
  top: 30%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 24rem;
  z-index: 100;
}

.eventAct {
  background-color: #ddd;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.eventAct:hover {
  filter: brightness(110%);
}

.eventActDone {
  background-color: #8fcaca;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.eventActDone:hover {
  filter: brightness(110%);
}

.form {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.body {
  margin: 1rem;
}

.eventsModal button {
  margin: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.eventsModal button:hover {
  background-color: #ccc;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 2rem;
  justify-content: space-between;
  border-top-width: 1px;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.task {
  color: #4b5563;
  line-height: 1.75rem;
  width: 80%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  background-color: white;
  margin-top: 2rem;
}

.task:hover {
  background-color: #f9fafb;
}

.task:focus {
  border-color: #3b82f6;
  background-color: #f9fafb;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: block;
  position: fixed;
  left: 25%;
  top: 3%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* ACERTAR REZISE */
  min-width: 58rem;
  max-width: 58rem;
  /* ACERTAR REZISE */
  min-height: 35rem;
  max-height: 35rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modalHeader {
  display: flex;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.modalBody {
  padding: 2rem;
  text-align: justify;
  min-height: 33rem;
  max-height: 33rem;
  overflow-y: scroll;
}

.modalBody button {
  margin-bottom: 1rem;
}

.modalBody h3 {
  padding-bottom: 3rem;
  text-align: center;
}

.modalBody label {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: bold;
}

.modalPractice button {
  cursor: pointer;
}

.modalPractice button:hover { 
  background-color: white;
}

.modalImg {
  width: 6rem;
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.pillarModal {
  display: block;
  position: fixed;
  left: 35%;
  top: 10%;
  transform: translate(-50%, -50%);
  align-items: center;
  /* ACERTAR REZISE */
  min-width: 28rem;
  max-width: 28rem;
  /* ACERTAR REZISE */
  min-height: 25rem;
  max-height: 25rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.pillar {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #4b5563;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pillar input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* On mouse-over, add a grey background color */
.pillar:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.pillar input:checked ~ .checkmark {
  background-color: #009688ff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.pillar input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.pillar .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media(max-width:1100px){
  .day_name {
    /* Hide the text. */
    text-indent: 5%;
    white-space: nowrap;
    overflow: hidden;
  }
}