.buttonEnter {
  width: 20rem;
  height: 3rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00bcd4ff;
  box-shadow: 5px 10px 30px #ccc;
  border-radius: 10px;
  color:#ffffff;
  cursor: pointer;
  font-weight: bold;
}

.buttonEnter:hover {
  filter: brightness(110%);
  transition: all ease 0.5s;
  color:#ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  object-fit: contain;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.login {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  height: 100vh;
  width: 100%;
  padding-top: 3rem;
  overflow-y: scroll;
  object-fit: contain;
}

.loginForm {
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
}

.loginForm h1 {
  text-align: center;
}

.loginForm input {
  margin-left: auto;
  margin-right: auto;
}

.form link:hover {
  font-weight: bold;
}

.forms {
  padding: 1rem;
  margin-bottom: 7rem;
}

.forms h1 {
  text-align: center;
  padding-bottom: 2rem;
}

.forms form {
  padding-top: 3rem;
}

.error {
  color: red;
}

@media (max-width: 40rem) {
  .login {
    grid-template-columns: 1fr;
  }
  .forms {
    max-width: 90%;
  }
}

/* Google Button */
.loginWithGoogleBtn {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
    
  margin: 2rem;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.loginWithGoogleBtn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.loginWithGoogleBtn:active {
  background-color: #eeeeee;
}

.loginWithGoogleBtn:focus {
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}

.loginWithGoogleBtn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  cursor: not-allowed;
}

/* Mairimed Button */
.loginWithMairimedBtn {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
    
  margin: 2rem;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(../../assets/mairiniBtn.png);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 2px 1px;
}

.loginWithMairimedBtn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.loginWithMairimedBtn:active {
  background-color: #eeeeee;
}

.loginWithMairimedBtn:focus {
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}

.loginWithMairimedBtn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  cursor: not-allowed;
}